import * as UserActions from '../../../actions/user';
import { BonusRecipientAutocomplete } from '../../../components/complex/bonus-recipient-autocomplete';
import { CreateUser, UserActions as UserActionsModel } from '../../../model/user';
import { OnSubmitHandler, useInputGroup } from '../../../hooks/useInputGroup';
import { ParamasterUserAutocomplete } from '../../../components/complex/paramaster-user-autocomplete';
import { Password } from '../../../components/utils/validators';
import { RoleAutocomplete } from '../../../components/complex/role-autocomplete';
import { WikusButton, WikusButtonRow } from '../../../components/core/button';
import { WikusInputRow, WikusTextField } from '../../../components/core/input';
import { WikusPanel } from '../../../components/core/card';
import { omit } from 'lodash';
import { useActions } from '../../../actions';
import { useCallback } from 'react';
import { useIntl } from 'react-intl'

export const CreateUserPanel = () => {
  const intl = useIntl();
  const CreateUserInputGroup = 'create-user';
  const title = intl.formatMessage({ id: 'create-user.title' });

  const userActions: typeof UserActions = useActions(
    UserActions
  );

  const _onSubmit: OnSubmitHandler = async () => {
    if (!isValid) {
      return;
    }

    const { type } = await userActions.createUser({
      ...omit(value, ['bonusRecipient', 'paramasterUsers']),
      paramasterUserIds: value.paramasterUsers?.map((u: any) => u.id),
      bonusRecipientId: value.bonusRecipient?.id,
      role: value.role.id,
    } as CreateUser);
    const isSuccess = type === UserActionsModel.CREATE_USER_SUCCESS;

    if (isSuccess) {
      clear();
    }

    return isSuccess;
  }
  const {
    value,
    isValid,
    clear,
    onSubmit,
    createInputProps,
    isSubmitting,
  } = useInputGroup(CreateUserInputGroup, _onSubmit);
  const passwordValidator = useCallback((password: unknown) => {
    return Password([value?.firstName, value?.lastName])(password);
  }, [value?.firstName, value?.lastName])

  return (
    <WikusPanel title={title}>
      <form onSubmit={onSubmit}>
        <WikusInputRow>
          <ParamasterUserAutocomplete {...createInputProps('paramasterUsers')} />
        </WikusInputRow>
        <WikusInputRow>
          <WikusTextField {...createInputProps('firstName')} xs={6} />
          <WikusTextField {...createInputProps('lastName')} xs={6} />
        </WikusInputRow>
        <WikusInputRow>
          <WikusTextField {...createInputProps('email')} xs={6} />
          <WikusTextField {...createInputProps('password')} validator={passwordValidator} type="password" xs={6} />
        </WikusInputRow>
        <WikusInputRow>
          <RoleAutocomplete {...createInputProps('role')} xs={6} />
          <BonusRecipientAutocomplete {...createInputProps('bonusRecipient')} validator={undefined} xs={6} />
        </WikusInputRow>
        <WikusButtonRow>
          <WikusButton
            type="submit"
            color="secondary"
            disabled={isSubmitting}>
            {intl.formatMessage({
              id: 'create-user.submit',
            })}
          </WikusButton>
        </WikusButtonRow>
      </form>
    </WikusPanel>
  )
}