import * as UserActions from '../../actions/user';
import { RequiredAutocomplete } from '../utils/validators';
import { Skeleton } from '@material-ui/lab';
import { UsersFilter } from '../../model/user';
import { WikusAutocomplete, WikusAutocompleteProps } from '../core/autocomlete';
import { useAutocomplete } from '../../hooks/useAutocomplete';

export const ParamasterUserAutocomplete = (props: Omit<WikusAutocompleteProps, 'options'>) => {
  const { isLoading, filterOptions, createAutocompleteProps } = useAutocomplete<typeof UserActions, UsersFilter>(UserActions, 'user-details');

  if (isLoading) {
    return (
      <Skeleton variant="rect" height={48} width="100%" />
    );
  }

  return (
    <WikusAutocomplete
      {...props}
      {...createAutocompleteProps('paramasterUsers')}
      disabled={!filterOptions?.paramasterUsers?.length}
      validator={filterOptions?.paramasterUsers?.length ? RequiredAutocomplete : undefined}
      multiple={true} />
  )
}